<template>
  <div class="w-full pt-5 pb-5">
    <div class="flex">
      <div style="width: 250px">
        <h1 class="text-xl text-left font-extrabold ml-4 mt-3">
          Advance and Loans
        </h1>
      </div>
    </div>
    <div class="px-3">
      <div class="mt-8">
        <Tab
          :tabs="['Advance', 'Loans', 'Consolidated Advance and Loans']"
          border
          :active-tab="currentTab"
          @currentTab="currentTab = $event"
        />
      </div>
      <advance v-if="currentTab === 'Advance'" mode="ess" />
      <Loans v-if="currentTab === 'Loans'" mode="ess" />
      <ConsolidatedAdvance
        v-if="currentTab === 'Consolidated Advance and Loans'"
        mode="ess"
      />
    </div>
  </div>
</template>

<script>
import Tab from "@scelloo/cloudenly-ui/src/components/tab";
import Advance from "@/modules/Admin/BenefitsModule/AdvanceLoans/Advance";
import Loans from "@/modules/Admin/BenefitsModule/AdvanceLoans/Loans";
import ConsolidatedAdvance from "@/modules/Admin/BenefitsModule/AdvanceLoans/ConsolidatedAdvance";

export default {
  name: "ManagerAdvanceLoans",
  components: {
    Tab,
    Advance,
    Loans,
    ConsolidatedAdvance
  },
  data() {
    return {
      currentTab: "Advance"
    };
  }
};
</script>
